/* eslint-disable i18next/no-literal-string */
import { Button, Grid } from "@material-ui/core";

export const NWStyleGuideButtons = (): JSX.Element => {
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={6}>
        <Button variant="contained">Button Contained</Button>
      </Grid>

      <Grid item={true} xs={6}>
        <Button variant="contained" disabled={true}>
          Button Contained
        </Button>
      </Grid>

      <Grid item={true} xs={6}>
        <Button variant="outlined">Button Outlined</Button>
      </Grid>

      <Grid item={true} xs={6}>
        <Button variant="outlined" disabled={true}>
          Button Outlined
        </Button>
      </Grid>

      <Grid item={true} xs={6}>
        <Button variant="text">Button Text</Button>
      </Grid>

      <Grid item={true} xs={6}>
        <Button variant="text" disabled={true}>
          Button Text
        </Button>
      </Grid>
    </Grid>
  );
};
