import { Box, Typography } from "@material-ui/core";
import { NWStyleGuideSectionProps } from "./NWStyleGuideSectionInterfaces";

export const NWStyleGuideSection = ({
  title,
  children,
}: NWStyleGuideSectionProps): JSX.Element => {
  return (
    <Box style={{ marginBottom: "3rem" }}>
      <Typography variant="h1">{title}</Typography>

      {children}
    </Box>
  );
};
