import { Container, Grid, useTheme } from "@material-ui/core";
import { NWStyleGuideButtons } from "./NWStyleGuideButtons/NWStyleGuideButtons";
import { NWStyleGuideColors } from "./NWStyleGuideColors/NWStyleGuideColors";
import { ColorMap } from "./NWStyleGuideColors/NWStyleGuideColorsInterfaces";
import { NWStyleGuideFonts } from "./NWStyleGuideFonts/NWStyleGuideFonts";
import { NWStyleGuideSection } from "./NWStyleGuideSection/NWStyleGuideSection";

export const NWStyleGuide = (): JSX.Element => {
  const theme = useTheme();

  const textColors: ColorMap[] = [
    {
      name: "Text primary",
      color: theme.typography.body1.color || theme.palette.text.primary,
    },
  ];

  const colors: ColorMap[] = [
    {
      name: "Primary Main",
      color: theme.palette.primary.main,
      contrastText: theme.palette.primary.contrastText,
    },
    {
      name: "Primary dark",
      color: theme.palette.primary.dark,
      contrastText: theme.palette.primary.contrastText,
    },
    {
      name: "Primary light",
      color: theme.palette.primary.light,
      contrastText: theme.palette.primary.contrastText,
    },
    {
      name: "Secondary Main",
      color: theme.palette.secondary.main,
      contrastText: theme.palette.secondary.contrastText,
    },
    {
      name: "Secondary Dark",
      color: theme.palette.secondary.dark,
      contrastText: theme.palette.secondary.contrastText,
    },
    {
      name: "Secondary Light",
      color: theme.palette.secondary.light,
      contrastText: theme.palette.secondary.contrastText,
    },
    {
      name: "Warning Main",
      color: theme.palette.warning.main,
    },
    {
      name: "Warning Dark",
      color: theme.palette.warning.dark,
    },
    {
      name: "Warning Light",
      color: theme.palette.warning.light,
    },
    {
      name: "Success Main",
      color: theme.palette.success.main,
    },
    {
      name: "Success Dark",
      color: theme.palette.success.dark,
    },
    {
      name: "Success Light",
      color: theme.palette.success.light,
    },
    {
      name: "Error Main",
      color: theme.palette.error.main,
    },
    {
      name: "Error Dark",
      color: theme.palette.error.dark,
    },
    {
      name: "Error Light",
      color: theme.palette.error.light,
    },
  ];

  return (
    <Container maxWidth="md">
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <NWStyleGuideSection title={"Schriftschnitte"}>
            <NWStyleGuideFonts />
          </NWStyleGuideSection>
        </Grid>

        <Grid item={true} xs={12}>
          <NWStyleGuideSection title={"Textfarben"}>
            <NWStyleGuideColors colors={textColors} />
          </NWStyleGuideSection>
        </Grid>

        <Grid item={true} xs={12}>
          <NWStyleGuideSection title={"Farben"}>
            <NWStyleGuideColors colors={colors} />
          </NWStyleGuideSection>
        </Grid>

        <Grid item={true} xs={12}>
          <NWStyleGuideSection title={"Buttons"}>
            <NWStyleGuideButtons />
          </NWStyleGuideSection>
        </Grid>
      </Grid>
    </Container>
  );
};
