import { Helmet } from "react-helmet";
import { NWStyleGuide } from "../03-organisms/NWStyleGuide/NWStyleGuide";

export const StyleGuidePage = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta key="robots" name="robots" content="noindex" />
      </Helmet>

      <NWStyleGuide />
    </>
  );
};

export default StyleGuidePage;
