/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Grid, Typography, TypographyProps, useTheme } from "@material-ui/core";

const headlines: TypographyProps["variant"][] = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "body1",
  "body2",
];

export const NWStyleGuideFonts = (): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      {headlines.map((h) => (
        <Grid container={true} key={h} style={{ marginBottom: "3rem" }}>
          <Typography variant={h} key={h}>{`Ich bin eine ${
            h?.toString() || ""
          }`}</Typography>

          {h && Object.hasOwn(theme.typography, h) && (
            <>
              <Grid item={true} xs={12}>
                <Typography variant="body2">{`${
                  // @ts-expect-error cant figure out how to use h as index
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  (theme.typography[h] as CSSProperties)?.fontFamily || ""
                }`}</Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Typography variant="body2">
                  {`${
                    // @ts-expect-error cant figure out how to use h as index
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    (theme.typography[h] as CSSProperties)?.fontSize || ""
                  }`}
                </Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Typography variant="body2">{`${
                  // @ts-expect-error cant figure out how to use h as index
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  (theme.typography[h] as CSSProperties)?.fontWeight || ""
                }`}</Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Typography variant="body2">{`${
                  // @ts-expect-error cant figure out how to use h as index
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  (theme.typography[h] as CSSProperties)?.color || ""
                }`}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      ))}
    </>
  );
};
