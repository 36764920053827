/* eslint-disable i18next/no-literal-string */
import { Box, Grid, Typography } from "@material-ui/core";
import { NWStyleGuideColorsProps } from "./NWStyleGuideColorsInterfaces";

export const NWStyleGuideColors = ({
  colors,
}: NWStyleGuideColorsProps): JSX.Element => {
  return (
    <Grid container={true} spacing={4}>
      {colors.map((c) => (
        <Grid item={true} key={c.name} xs={4}>
          <Box
            key={`${c.color}-${c.name}`}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ backgroundColor: c.color }}
            width={350}
            height={200}
          />

          <Box flexDirection="column">
            <Typography>{c.name}</Typography>

            <Typography variant="h5">{c.color}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
